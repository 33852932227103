<template>
<div v-if="getAllowFullSite" class="main content-page donate">

    <div class="vertical-image"></div>

    <div v-if="loading" class="content-page-inner donate-content donate-content-loading">
        <h1>SUPPORT THE GREAT AWAKENING PROJECT</h1>
        <div class="loading">
            <font-awesome-icon icon="spinner" class="icon fa-spin loading-icon"/>
            <p v-html="loadingMessage"></p>
        </div>
    </div>

    <div v-else class="content-page-inner donate-content">
        <h1>SUPPORT THE GREAT AWAKENING PROJECT</h1>
        <form class="donate-form">
            <h3>Contact Information</h3>
            <div class="two-columns">
                <div>
                    <label for="email">Email Address</label>
                    <input type="text" id="email" name="email" v-model="donation.email_address">
                </div>
                <div>
                    <label for="phone">Phone Number</label>
                    <input type="tel" id="phone" name="phone" v-model="donation.phone_number">
                </div>
            </div>

            <div class="spacer"></div>

            <h3>Your Contribution</h3>
            <div class="select-contribution">
                <div class="donate-select">
                    <input type="radio" id="donation_50000" name="donation" value="50000" v-model="donation.select_amount">
                    <label for="donation_50000">$50,000</label><br>
                </div>
                <div class="donate-select">
                    <input type="radio" id="donation_25000" name="donation" value="25000" v-model="donation.select_amount">
                    <label for="donation_25000">$25,000</label><br>
                </div>
                <div class="donate-select">
                    <input type="radio" id="donation_10000" name="donation" value="10000" v-model="donation.select_amount">
                    <label for="donation_10000">$10,000</label>
                </div>
                <div class="donate-select">
                    <input type="radio" id="donation_5000" name="donation" value="5000" v-model="donation.select_amount">
                    <label for="donation_5000">$5,000</label>
                </div>
                <div class="donate-select">
                    <input type="radio" id="donation_other" name="donation" value="other" v-model="donation.select_amount">
                    <label for="donation_other">Other</label>
                    <input type="number" placeholder="$" @click="donation.select_amount = 'other'" v-model="donation.other_amount">
                </div>
            </div>

            <div class="spacer"></div>

            <template v-if="designations.length > 1">
                <h3>Designation</h3>
                <select v-model="donation.designation_id">
                    <option value="">Select...</option>
                    <option v-for="(designation, index) in designations" :value="designation.designation_id">{{ designation.description }}</option>
                </select>
                <div class="spacer"></div>
            </template>

            <h3>Billing Info</h3>
            <label for="name">Name on Card</label>
            <input type="text" id="name" name="name" v-model="donation.full_name">
            <label for="creditcard">Card Number</label>
            <input type="text" id="creditcard" name="creditcard" v-model="donation.card_number">

            <div class="two-columns">
                <div>
                    <label for="expiry">Expiration Date</label>
                    <div>
                        <select class="input-half" v-model="donation.expiration_month">
                            <option value="">Month...</option>
                            <option v-for="(month, index) in expirationMonths" :value="month">{{ month }}</option>
                        </select>
                        <select class="input-half" v-model="donation.expiration_year">
                            <option value="">Year...</option>
                            <option v-for="(year, index) in expirationYears" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label for="cvv">C V V (back of card)</label><br>
                    <input type="text" id="cvv" name="cvv" class="input-half" v-model="donation.cvv">
                </div>
            </div>

            <label for="billing">Billing Address</label>
            <input type="text" id="billing" name="billing" v-model="donation.address_1">
            <label for="city">City</label>
            <input type="text" id="city" name="city" v-model="donation.city">
            <div class="two-columns">
                <div>
                    <label for="state">State</label>
                    <state-select :selectedState="donation.state" @updateSelected="updateSelectedState"></state-select>
                </div>
                <div>
                    <label for="zip">Zip Code</label>
                    <input type="text" id="zip" name="zip" v-model="donation.postal_code">
                </div>
            </div>

            <label for="note">Any comments or special instructions for this donation?</label>
            <input type="text" id="note" name="note" v-model="donation.note">

            <div class="two-columns controls">
                <button :class="['button-dark', { disabled: authorizing }]" @click.prevent="authorizeDonation()"><font-awesome-icon v-if="authorizing" icon="spinner" class="fa-spin" /><span v-else>Authorize Donation</span></button>
                <div class="message">{{ message }}</div>
            </div>

        </form>

        <div class="extra-info">
            <div class="two-columns">
                <div class="contact-info">
                    <h3>Want to mail your donation?</h3>
                    <label>Make checks payable to...</label>
                    <div>The Great Awakening Project</div>
                    <div class="spacer"></div>
                    <label>And mail to...</label>
                    <div>
                        <div>{{ mailingAddress.company_name }}</div>
                        <div>{{ mailingAddress.address_1 }}</div>
                        <div v-if="mailingAddress.address_2">{{ mailingAddress.address_2 }}</div>
                        {{ mailingAddress.city }}, {{ mailingAddress.state }} {{ mailingAddress.postal_code }}
                    </div>
                </div>
                <div class="contact-info">
                    <h3>Questions about donations?</h3>
                    <label>Email GAP at...</label>
                    <div><a :href="'mailto:' + emailToAddress">{{ emailToAddress }}</a></div>
                    <div class="spacer"></div>
                    <label>See how your information is protected...</label>
                    <div><router-link to="/privacy-policies">Privacy Policies</router-link></div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
export default {
    name: 'donate',
    components: {
        'state-select': StateSelect
    },
    data () {
        return {
            loading: true,
            loadingMessage: '',
            message: '',
            mailingAddress: {},
            emailToAddress: '',
            howHeardOptions: [],
            spheres: [],
            designations: [],
            expirationMonths: [],
            expirationYears: [],
            donation: {
                email_address: '',
                phone_number: '',
                select_amount: '',
                other_amount: '',
                amount: '',
                designation_id: '',
                full_name: '',
                card_number: '',
                expiration_month: '',
                expiration_year: '',
                cvv: '',
                address_1: '',
                city: '',
                state: '',
                postal_code: '',
                note: ''
            },
            authorizing: false
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
        setTimeout(() => {this.loadingMessage = "Your browser can't seem to connect to our server.<br>Try reloading this page."}, 3000)
        this.apiCall({ destination: 'get_contact_form_options', data: { context: 'donate' } }).then(obj => {
            this.loading = false
            this.loadingMessage = ''
            if (obj.status == 'success') {
                this.mailingAddress = obj.mailing_address
                this.emailToAddress = obj.email_address
                this.howHeardOptions = obj.how_heard
                this.spheres = obj.spheres
                this.designations = obj.designations
            } else {
                this.message = obj.message
            }
        })
        for (let i = 1; i <= 12; i++) {
            this.expirationMonths.push(i.toString().padStart(2, '0'))
        }
        var currentYear = new Date().getFullYear()
        for (let i = 0; i < 15; i++) {
            this.expirationYears.push(currentYear + i)
        }
    },
    methods: {
		updateSelectedState(state) {
			this.donation.state = state
		},
        authorizeDonation() {
            if (this.authorizing) {
                return false
            }
            this.message = ''
            let validateMessage = ''
            if (!validateMessage && !this.isValidEmailAddress(this.donation.email_address)) {
                validateMessage = 'Please enter a full email address.'
            }
            if (!validateMessage && !this.isValidPhoneNumber(this.donation.phone_number)) {
                validateMessage = 'Please enter a complete phone number.'
            }
            this.donation.amount = this.donation.select_amount == 'other' ? this.donation.other_amount : this.donation.select_amount
            if (!validateMessage && (!this.donation.amount || isNaN(this.donation.amount))) {
                validateMessage = 'Please enter your donation amount.'
            }
            if (!validateMessage && !this.donation.full_name) {
                validateMessage = 'Please enter the Name on Card.'
            }
            if (!validateMessage && !this.isValidCreditCardNumber(this.donation.card_number)) {
                validateMessage = 'Please enter a valid Card Number.'
            }
            if (!validateMessage && (!this.donation.expiration_month || !this.donation.expiration_year)) {
                validateMessage = 'Please enter the Expiration Date.'
            }
            if (!validateMessage && !this.donation.cvv) {
                validateMessage = 'Please enter the CVV card code.'
            }
            if (!validateMessage && !this.donation.address_1) {
                validateMessage = 'Please enter the Billing Address.'
            }
            if (!validateMessage && !this.donation.city) {
                validateMessage = 'Please enter the billing City.'
            }
            if (!validateMessage && !this.donation.state) {
                validateMessage = 'Please enter the billing State.'
            }
            if (!validateMessage && !this.donation.postal_code) {
                validateMessage = 'Please enter the billing Zip Code.'
            }
            if (validateMessage) {
                this.message = validateMessage
                return false
            }
            this.message = 'Authorizing your donation...'
            this.authorizing = true
            this.apiCall({ destination: 'authorize_donation', data: this.donation }).then(obj => {
                if (obj.status == 'success') {
                    //this.message = 'TODO: route to confirmation page?'
                    this.$router.push('/donate-complete?token=' + obj.token)
                } else {
                    this.message = obj.message
                    this.authorizing = false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/donate1.jpg");
    background-size: auto 100%;
}
.donate-content {
    padding: 1em 4em 1em 4em;
    text-align: left;
}
.donate-content-loading {
    height: 42em;
    .loading {
        text-align: center;
        padding: 3em 0;
    }
    .loading-icon {
        font-size: 2em;
        margin: 1em;
        color: #666;
    }
}
.select-contribution {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    align-items: baseline;
    margin: 0 0 0.5em 0;
    .donate-select {
        input[type=radio] {
            display: none;
        }
        label {
            font-size: 1em;
            padding: 0.5em 1em;
            background-color: #fff;
            border: 1px solid #3c3c3c;
            color: #3c3c3c;
            display: inline-block;
            height: 1.15em;
            width: 3em;
            text-align: center;
            border-radius: 3px;
        }
        input[type=number] {
            display: inline-block;
            width: 7em;
            height: 2.05em;
            margin: 0;
        }
    }
    @media all and (min-width: 1200px) {
        grid-template-columns: 5em 5em 5em 5em 1fr;
    }
}

input[type=radio]:checked ~ label{
    background-color: #3c3c3c;
    color: #fff;
}
input[type=radio]:checked ~ input[type=number]{
    border: 2px solid #3c3c3c;
}
.controls {
    margin: 1em 0;
    align-items: center;
}
.extra-info {
    margin: 2em 0 0 0;
    padding: 1em 0 0 0;
    border-top: 1px solid #0d0d0d;
    .contact-info {
        margin: 0 0 1.5em 0;
        @media all and (min-width: 1200px) {
            margin: 0;
        }
    }
}
</style>
